/* @flow */

const isServer = typeof window === 'undefined';

let { PopupOpenError } = isServer ? {} : require('belter/src');

let { ZoidComponent, ZoidComponentInstance } = isServer
  ? {}
  : require('./component');

let { RenderOptionsType } = isServer ? {} : require('./parent');
let { create, destroy, destroyComponents, destroyAll } = isServer
  ? {}
  : require('./component');

let { PROP_TYPE, PROP_SERIALIZATION, CONTEXT, EVENT } = isServer
  ? {}
  : require('./constants');

export { PopupOpenError };

export type { ZoidComponent, ZoidComponentInstance };
export type { RenderOptionsType };

export { create, destroy, destroyComponents, destroyAll };
export { PROP_TYPE, PROP_SERIALIZATION, CONTEXT, EVENT };
